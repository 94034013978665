import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '../container'
import { SectionTitle } from '../section-title'
import { ArticleCard } from '../blog/article-card'
import { graphql, useStaticQuery } from 'gatsby'

export function BlogRecent() {
  const { blogRecommended } = useStaticQuery(
    graphql`
      query {
        blogRecommended: allDatoCmsBlogRecommended {
          nodes {
            locale
            title
            subtitle
            slug
            recommendedArticles {
              contentSummary
              title
              slug
              imageSummary {
                gatsbyImageData(aspectRatio: 1.6)
              }
              meta {
                publishedAt(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `
  )
  const { i18n } = useTranslation()
  const { title, subtitle, recommendedArticles, slug } = blogRecommended.nodes.find( ({ locale }) => locale === i18n.language)
  return (
    <Container color='bg-beige'>
      <SectionTitle title={title} subtitle={subtitle} centered={true} />
      <div className="grid grid-cols-1 gap-6 mx-auto sm:grid-cols-3 font-light">
        {recommendedArticles.map((post, index) => (
          <ArticleCard baseUrl={slug} post={post} key={index} />
        ))}
      </div>
    </Container>
  )
}
