import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { ObservationList } from './observations-list'

export interface ProductItem {
  title: string
  description: string
  price: string
  image: IGatsbyImageData
  slug: string
  observations: any[]
  sticker?: string
}

interface ProductItemList {
  products: ProductItem[]
}

export function ProductCard({ products }: ProductItemList) {
  return (
    <>
      {products.map((product, index) => (
        <Link to={product.slug} className="group relative bg-beige rounded-2xl" key={index}>
          {product.sticker ? (
            <p className="absolute bg-abel-green text-white text-xs sm:text-sm py-2 px-3 sm:px-6 rounded-full -top-5 right-4 z-40">
              {product.sticker}
            </p>
          ) : (
            ''
          )}

          <GatsbyImage
            image={{ ...product.image }}
            alt="product image"
            className="rounded-t-2xl w-full"
          />
          <div className="py-4 px-4">
            <div className="md:flex md:gap-4 pb-4 justify-between">
              <div className="my-auto">
                <p className="text-sm sm:text-base font-medium group-hover:text-abel-green">
                  {product.title}
                </p>
              </div>
              <div className="my-auto">
                <p className="font-bold group-hover:text-abel-green">€{Math.round(Number(product.price)*100)/100}</p>
              </div>
            </div>

            <div className="grid gap-1">
              <ObservationList observations={product.observations} />
            </div>
          </div>
        </Link>
      ))}
    </>
  )
}
