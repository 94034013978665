import * as React from "react"
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'

export function TakeQuiz() {
  const { t, i18n } = useTranslation()
  const prefix = i18n.language === 'en' ? '' : '/' + i18n.language

  function goTakeQuiz() {
    navigate(prefix + '/' + t('quiz-url'));
  }

  return (
    <div className="text-center">
      <button
        type="submit"
        onClick={goTakeQuiz}
        className="border-abel-green bg-abel-green text-white rounded-full w-80 text-center py-3 text-lg mb-12 hover:bg-abel-gray"
      >
        {t('product-page-quiz')}
      </button>
    </div>
  )
}
