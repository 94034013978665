import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '../components/section-title'
import { Container } from '../components/container'
import { ProductCard, ProductItem } from '../components/product-card'
import { ProductBanner } from '../components/product-banner'
import { HowItWorks } from '../components/how-it-works'
import { BlogRecent } from '../components/blog/blog-recent'
import { TakeQuiz } from '../components/take-quiz'

const translationsEn = require('../locale/en/translation.json')
const translationsNl = require('../locale/nl/translation.json')
const translationsDe = require('../locale/de/translation.json')
const translationsFr = require('../locale/fr/translation.json')

const hrefLangLinks = {
  en: translationsEn['our-tests-url'],
  fr: translationsFr['our-tests-url'],
  nl: translationsNl['our-tests-url'],
  de: translationsDe['our-tests-url'],
}

export default function ProductsPage({
  data: { shopifyProducts, datoProducts, productPage },
}) {
  const { t } = useTranslation()
  const mergedProducts = datoProducts.nodes.map(t1 => ({
    ...t1,
    ...shopifyProducts.nodes.find(t2 => t2.storefrontId === `gid://shopify/Product/${t1.shopifyId}`),
  }))

  const parsedProducts = mergedProducts.map(
    product =>
      ({
        title: product.title,
        description: product.extra,
        price: product.priceRangeV2.minVariantPrice.amount,
        image: product.featuredImage.localFile.childImageSharp.gatsbyImageData,
        observations: product.observation,
        slug: `${t('link-base') + t('our-tests-url')}/${
          product.category.slug
        }/${product.slug}`,
        sticker: product.sticker,
      } as ProductItem)
  )

  const seoMetaTags = {
    tags: [
      {
        tagName: 'title',
        content: 'Our Tests | Abelcare',
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:title',
          content: 'Our Tests',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:title',
          content: 'Our Tests',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'description',
          content:
            'Test yourself for STDs, vitamins, hormones and COVID-19. Discreet, fast and reliable.',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:description',
          content:
            'Test yourself for STDs, vitamins, hormones and COVID-19. Discreet, fast and reliable.',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:description',
          content:
            'Test yourself for STDs, vitamins, hormones and COVID-19. Discreet, fast and reliable.',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:card',
          content: 'summary',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'article:modified_time',
          content: '2022-03-19T12:24:06Z',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'article:published_time',
          content: '2019-09-18T10:23:51Z',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:locale',
          content: 'en_EN',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:type',
          content: 'article',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image',
          content:
            'https://www.datocms-assets.com/16172/1613144621-img8311.jpg?w=1000&fit=max&fm=jpg',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:image',
          content:
            'https://www.datocms-assets.com/16172/1613144621-img8311.jpg?w=1000&fit=max&fm=jpg',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image:width',
          content: 5184,
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image:height',
          content: 1927,
        },
      },
    ],
  }

  return (
    <Layout hrefLangLinks={hrefLangLinks} seoMetaTags={productPage.seoMetaTags}>
      <ProductBanner />
      <Container>
        <SectionTitle
          title={productPage.title}
          subtitle={productPage.subtitle}
          main={true}
          centered={true}
        />
        <TakeQuiz />
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-8 mx-auto font-light">
          <ProductCard products={parsedProducts} />
        </div>
      </Container>
      <HowItWorks />
      {/* <InfoTabBox /> */}
      <BlogRecent />
    </Layout>
  )
}

export const query = graphql`
  query ($locale: String!) {
    productPage: datoCmsProductPage(locale: { eq: $locale }) {
      title
      subtitle
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    shopifyProducts: allShopifyProduct {
      nodes {
        id
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
        storefrontId
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    datoProducts: allDatoCmsProduct(
      sort: { order: ASC, fields: position }
      filter: { locale: { eq: $locale }, published: { eq: true } }
    ) {
      nodes {
        title
        description
        typeDescription
        shopifyId
        extra
        observation {
          title: name
        }
        collectionType {
          name
          slug
          tagColor
        }
        slug
        category {
          originalId
          name
          description
          slug
          image {
            fixed(width: 48) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
        sticker
      }
    }
  }
`
